"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.depsAreEqual = depsAreEqual;

function depsAreEqual(prevDeps, deps) {
  for (var i = 0; i < prevDeps.length; i++) {
    if (Object.is(prevDeps[i], deps[i])) {
      continue;
    }

    return false;
  }

  return true;
}